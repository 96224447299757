import styled from "styled-components";

export const Input = styled.input`
	font-family: "LINESeedKR", "LINESeedJP", -apple-system, BlinkMacSystemFont,
		system-ui, "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
		"Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		sans-serif;
	color: ${({ theme }) => theme.textColor};
	background-color: ${({ theme }) => theme.unselectedBoxColor};
	border-radius: 10px;
	padding: 10px 10px;
	text-align: right;
	width: 250px;
	border: none;
	outline: none;
	transition: all 0.25s;

	&:focus {
		box-shadow: 0 0 0 2px ${(props) => props.color} inset;
	}

	&::placeholder {
		color: ${({ theme }) => theme.unselectedTextColor};
	}

	appearance: none;
	-moz-appearance: none;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	&::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		position: absolute;
		right: 0;
	}
`;

export const Title = styled.p`
	text-align: right;
	font-weight: 600;
	color: ${({ theme }) => theme.textColor};
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 5px;
`;
