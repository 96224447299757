import React from "react";
import { Input, Label } from "./radio.style";

interface RadioProps {
	label: string;
	description?: string;
	name: string;
	id: string;
	color: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	checked?: boolean;
}

const Radio = (props: RadioProps) => {
	return (
		<>
			<Input
				type="radio"
				name={props.name}
				id={props.id}
				color={props.color}
				onChange={props.onChange}
				checked={props.checked}
			/>
			<Label htmlFor={props.id} color={props.color}>
				<h2 className="font-base" style={{ fontWeight: 800 }}>
					{props.label}
				</h2>
				{props.description && (
					<p className="font-sm" style={{ marginTop: "5px" }}>
						{props.description}
					</p>
				)}
			</Label>
		</>
	);
};

export default Radio;
