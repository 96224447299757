import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import korean from "./ko-KR.json";
import english from "./en-US.json";

i18next.use(initReactI18next).init({
	resources: {
		ko: korean,
		en: english,
	},
	lng: "en",
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18next;
