import { useEffect, useState } from "react";
import { Input, Label, Wrapper } from "./toggle.style";

export interface ToggleItemProps {
	label: string;
	id: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	checked?: boolean;
}

interface ToggleProps {
	name: string;
	items: ToggleItemProps[];
	color: string;
	size?: number;
}

const Toggle = (props: ToggleProps) => {
	const [index, setIndex] = useState(0);
	useEffect(() => {
		props.items.forEach((item, i) => item.checked && setIndex(i));
	}, [props.items]);
	return (
		<>
			<Wrapper color={props.color} size={props.size} index={index}>
				{props.items.map((item, i) => (
					<>
						<Input
							type="radio"
							index={i}
							name={props.name}
							id={item.id}
							onChange={item.onChange}
							checked={item.checked}
							color={props.color}
						/>
						<Label htmlFor={item.id} className="font-base" size={props.size}>
							<p>{item.label}</p>
						</Label>
					</>
				))}
			</Wrapper>
		</>
	);
};

export default Toggle;
