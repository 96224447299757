import { PropsWithChildren } from "react";
import { IconType } from "react-icons";
import styled from "styled-components";
import { Skeleton } from "../../../components/skeleton/skeleton.style";
import { BaseGray } from "../../../style/base_colors";

const Wrapper = styled.div`
	display: flex;
	width: 620px;
	margin: 25px 0;
	@media (max-width: 700px) {
		flex-direction: column;
		width: calc(100vw - 60px);
	}
`;

const InformationWrapper = styled.div`
	width: 200px;
	height: 100%;
	@media (max-width: 700px) {
		width: calc(100vw - 60px);
	}
`;

const IconWrapper = styled.div`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	content: "";
	width: 25px;
	height: 25px;
	border-radius: 5px;
	background-color: ${({ color }) => color + "33"};
`;

const Title = styled.h2`
	font-weight: 600;
	display: flex;
	align-items: flex-start;
	gap: 5px;
	margin-bottom: 5px;
	color: ${({ theme }) => theme.textColor};
`;

const Description = styled.p`
	font-weight: 400;
	margin: 0 0 10px 30px;
	color: ${({ theme }) => theme.assistTextColor};
`;

const ItemChildrenWrapper = styled.div`
	width: 380px;
	gap: 10px;
	@media (max-width: 700px) {
		width: calc(100vw - 60px);
	}
`;

interface ItemProps {
	title: string;
	description?: string;
	icon: IconType | React.FC<React.SVGProps<SVGSVGElement>>;
	color: string;
}

export const ItemWrapper = styled.div<{ wrap?: boolean }>`
	display: flex;
	justify-content: flex-end;
	gap: 5px;
	flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
`;

export const Item = (props: PropsWithChildren<ItemProps>) => {
	const Icon = props.icon;
	return (
		<Wrapper>
			<InformationWrapper>
				<Title className="font-lg">
					<IconWrapper color={props.color}>
						<Icon fill={props.color} size="18px" width="18px" height="18px" />
					</IconWrapper>
					<div style={{ marginTop: "2px" }}>{props.title}</div>
				</Title>
				{!!props.description && (
					<Description className="font-sm">{props.description}</Description>
				)}
			</InformationWrapper>
			<ItemChildrenWrapper>{props.children}</ItemChildrenWrapper>
		</Wrapper>
	);
};

export const SkeletonGroup = (props: PropsWithChildren<{}>) => {
	return (
		<GroupWrapper>
			<GroupTitle>
				<Skeleton width={130} height={26} />
			</GroupTitle>
			{props.children}
		</GroupWrapper>
	);
};

export const SkeletonItem = () => {
	return (
		<Wrapper>
			<InformationWrapper>
				<Title>
					<IconWrapper color={BaseGray} />
					<Skeleton width={100} height={20} />
				</Title>
				<Description>
					<Skeleton width={150} height={16} />
				</Description>
			</InformationWrapper>
			<ItemChildrenWrapper>
				<Skeleton width={180} height={30} />
			</ItemChildrenWrapper>
		</Wrapper>
	);
};

const GroupWrapper = styled.div`
	padding: 20px;

	& ${Wrapper}:not(:nth-child(2)):before {
		content: "";
		position: absolute;
		width: 600px;
		height: 2px;
		margin-top: -15px;
		border-radius: 1px 0 0 1px;
		background-color: ${({ theme }) => theme.unselectedBoxColor};
		@media (max-width: 700px) {
			width: calc(100vw - 40px);
		}
	}
`;

const GroupTitle = styled.h1`
	color: ${({ theme }) => theme.textColor};
	font-weight: 800;
`;

export const Group = (props: PropsWithChildren<{ title: string }>) => {
	return (
		<GroupWrapper>
			<GroupTitle className="font-xl">{props.title}</GroupTitle>
			{props.children}
		</GroupWrapper>
	);
};
