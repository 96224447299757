import styled from "styled-components";
import { IconType } from "react-icons";

const GroupPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	& h1,
	& p {
		color: ${({ theme }) => theme.textColor};
	}
`;

const AnimationIconWrapper = styled.div`
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: ${({ color }) => "0 0 0 15px " + color + " inset"};
	overflow: hidden;

	@keyframes lookup {
		0% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		50% {
			opacity: 1;
			transform: translateZ(0);
			box-shadow: ${({ color }) => "0 0 0 15px " + color + " inset"};
		}
		100% {
			margin-top: 20px;
			margin-bottom: 30px;
			transform: scale(2);
			box-shadow: ${({ color }) => "0 0 0 2px " + color + " inset"};
		}
	}

	animation: lookup 0.5s forwards;

	& > * {
		@keyframes icon-lookup {
			0% {
				transform: translate3d(0, -100%, 0);
			}
			100% {
				transform: translateZ(0);
			}
		}
		animation: icon-lookup 0.25s forwards 0.25s;
	}
`;

interface GroupPageProps {
	color: string;
	icon: IconType;
	title: string;
	description: string;
}

export const GroupPage = (props: GroupPageProps) => {
	const Icon = props.icon;
	return (
		<GroupPageWrapper>
			<AnimationIconWrapper color={props.color}>
				<Icon color={props.color} size="20px" />
			</AnimationIconWrapper>
			<h1 className="font-xl" style={{ textAlign: "center" }}>
				{props.title}
			</h1>
			<p className="font-base" style={{ margin: "10px", textAlign: "center" }}>
				{props.description}
			</p>
		</GroupPageWrapper>
	);
};
