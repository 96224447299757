import { Input, Label } from "./checkbox.style";
import { FaCheck } from "react-icons/fa6";

interface CheckBoxProps {
	label: string;
	name: string;
	id: string;
	color: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	checked?: boolean;
}

const CheckBox = (props: CheckBoxProps) => {
	return (
		<>
			<Input
				type="checkbox"
				name={props.name}
				id={props.id}
				color={props.color}
				onChange={props.onChange}
				checked={props.checked}
			/>
			<Label htmlFor={props.id}>
				<p className="font-base">{props.label}</p>
				{props.checked && (
					<FaCheck fill="#fff" size="15px" style={{ marginLeft: "5px" }} />
				)}
			</Label>
		</>
	);
};

export default CheckBox;
