import { MouseEventHandler, PropsWithChildren } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import styled from "styled-components";
import { ImportantWarnRed } from "../../../style/base_colors";
import { TFunction } from "i18next";

const Bar = styled.div<{ show?: boolean }>`
	display: flex;
	align-items: center;
	position: fixed;
	width: 590px;
	padding: 10px 10px 10px 20px;
	border-radius: 20px;
	outline: ${({ color }) => `solid 1px ${color}`};
	box-shadow: ${({ color }) => `0 5px 10px 1px ${color}66`};
	bottom: ${({ show }) => (show ? "20px" : "-60px")};
	background-color: ${({ theme }) => `${theme.wrapperBoxColor}a6`};
	backdrop-filter: blur(10px);
	transition: all 0.25s;
	z-index: 900;
	@media (max-width: 700px) {
		width: calc(100% - 50px);
	}

	& p {
		color: ${(props) =>
			props.color === ImportantWarnRed
				? props.color ?? props.theme.textColor
				: props.theme.textColor};
		transition: all 0.25s;
	}
`;

const Label = styled.label<{
	buttonType: "reset" | "save";
	hoverWhiteColor: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	border-radius: 10px;
	font-weight: ${({ buttonType }) => (buttonType === "reset" ? "400" : "600")};
	cursor: pointer;
	text-decoration: ${({ buttonType }) =>
		buttonType === "reset" ? "underline" : "none"};
	color: ${(props) =>
		props.buttonType === "reset" ? props.theme.unselectedTextColor : "#fff"};
	background-color: ${(props) =>
		props.buttonType === "reset" ? "" : props.color};
	transition: all 0.25s;

	&:hover {
		color: ${(props) =>
			props.buttonType === "reset" ? props.theme.textColor : ""};
		background-color: ${(props) =>
			props.buttonType === "save" ? props.hoverWhiteColor : ""};
	}
`;

const Input = styled.input`
	display: none;

	&:disabled + ${Label} {
		cursor: default;
		color: ${({ theme }) => theme.unselectedTextColor};
		background-color: ${({ theme }) => theme.unselectedBoxColor};
	}
`;

interface SaveBarProps {
	isEdited?: boolean;
	importantWarns: number;
	color: string;
	hoverWhiteColor: string;
	onReset: MouseEventHandler<HTMLInputElement>;
	onSubmit: MouseEventHandler<HTMLInputElement>;
	tr: TFunction; //<"translation", undefined>;
}

export const SaveBar = (props: PropsWithChildren<SaveBarProps>) => {
	const color = props.importantWarns === 0 ? props.color : ImportantWarnRed;
	return (
		<Bar color={color} show={props.isEdited}>
			{props.importantWarns === 0 ? (
				<p className="font-base">{props.tr("settings:save_bar.alert")}</p>
			) : (
				<>
					<RiErrorWarningFill
						size="20px"
						color={color}
						style={{ marginRight: "5px" }}
					/>
					<p className="font-base">
						{props.tr("settings:save_bar.error", {
							count: props.importantWarns,
						})}
					</p>
				</>
			)}
			<div style={{ marginLeft: "auto", gap: "5px", display: "flex" }}>
				<Input type="button" id="reset" onClick={props.onReset} />
				<Label
					className="font-base"
					htmlFor="reset"
					buttonType="reset"
					color={props.color}
					hoverWhiteColor={props.hoverWhiteColor}
				>
					{props.tr("settings:save_bar.reset")}
				</Label>
				<Input
					type="button"
					id="save"
					onClick={props.onSubmit}
					disabled={!(props.importantWarns === 0 && props.isEdited)}
				/>
				<Label
					className="font-base"
					htmlFor="save"
					buttonType="save"
					color={color}
					hoverWhiteColor={props.hoverWhiteColor}
				>
					{props.tr("settings:save_bar.save")}
				</Label>
			</div>
		</Bar>
	);
};
