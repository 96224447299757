export interface Theme {
	backgroundColor: string;

	textColor: string;
	assistTextColor: string;

	wrapperBoxColor: string;

	unselectedTextColor: string;
	unselectedBoxColor: string;
}

export const lightTheme: Theme = {
	backgroundColor: "#f0f2f8",
	textColor: "#000",
	assistTextColor: "#a4acba",
	wrapperBoxColor: "#ffffff",
	unselectedTextColor: "#6f788d",
	unselectedBoxColor: "#eaeef5",
};

export const darkTheme: Theme = {
	backgroundColor: "#2e384f",
	textColor: "#ffffff",
	assistTextColor: "#6f788d",
	wrapperBoxColor: "#181c27",
	unselectedTextColor: "#d7dbe6",
	unselectedBoxColor: "#475064",
};
