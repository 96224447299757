import styled from "styled-components";
import { TbAlertTriangleFilled, TbAlertHexagonFilled } from "react-icons/tb";
import {
	ImportantWarnRed,
	UnimportantWarnOrange,
} from "../../../style/base_colors";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 5px;
	font-weight: 600;
	color: ${({ color }) => color};
	padding: 10px 0 20px 0;
	@media screen and (max-width: 700px) {
		padding: 10px 0 20px 45px;
	}
`;

interface WarnProps {
	content: string;
	important?: boolean;
}

export const Warn = (props: WarnProps) => {
	const color = props.important ? ImportantWarnRed : UnimportantWarnOrange;
	return (
		<Wrapper className="font-xs" color={color}>
			{props.important ? (
				<TbAlertTriangleFilled color={color} />
			) : (
				<TbAlertHexagonFilled color={color} />
			)}
			{props.content}
		</Wrapper>
	);
};
