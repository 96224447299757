import { useParams } from "react-router-dom";
import Radio from "../../components/radio/radio";
import CheckBox from "../../components/checkbox/checkbox";
import TextBox from "../../components/textbox/textbox";
import Toggle from "../../components/toggle/toggle";
import { BackgroundColor, Container, Wrapper } from "./container/box.style";
import { Header, SkeletonHeader } from "./container/header.style";
import {
	Group,
	Item,
	ItemWrapper,
	SkeletonGroup,
	SkeletonItem,
} from "./container/item.style";
import { GroupPage } from "./container/group_page.style";
import { Warn } from "./container/warn.style";
import { CitizenBlue, MafiaRed } from "../../style/base_colors";
import { SaveBar } from "./container/save_bar";
import { FaListCheck } from "react-icons/fa6";
import { useSettings } from "./use_settings";
import { IoAlert } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { BiSolidRename } from "react-icons/bi";
import { FaUsers } from "react-icons/fa6";
import { IoMdMicrophone } from "react-icons/io";
import { PiPasswordFill } from "react-icons/pi";
import { MdWbSunny, MdPreview, MdOutlineModeStandby } from "react-icons/md";
import { FaPoll } from "react-icons/fa";
import { ReactComponent as TeamIcon } from "../../asset/icons/team.svg";
import { Helmet } from "react-helmet-async";

const SettingsPage = () => {
	const { code } = useParams();
	const conf = useSettings(code ?? "");
	const { t } = conf;

	return (
		<>
			<Helmet>
				<title>{t("settings:meta_data.title")}</title>
				<meta name="theme-color" content={conf.color} />
			</Helmet>
			<BackgroundColor color={conf.color} />
			<Wrapper>
				{conf.process === "LOADING" || conf.process === "ERROR" ? (
					<SkeletonHeader />
				) : (
					<Header
						roomType={t(
							`settings:header.roomtype.${conf.origin.current?.settings.roomType}`
						)}
						code={t("settings:header.code", {
							code: conf.origin.current?.settings.code,
						})}
						name={
							conf.origin.current?.settings.name ||
							t("settings:header.name_base", {
								username: conf.origin.current?.username,
							})
						}
					/>
				)}
				<Container isEdited={conf.isEdited}>
					{conf.process === "LOADING" && (
						<SkeletonGroup>
							<SkeletonItem />
							<SkeletonItem />
							<SkeletonItem />
						</SkeletonGroup>
					)}
					{conf.process === "ERROR" && (
						<GroupPage
							color={conf.color}
							icon={IoAlert}
							title={t("settings:process.error.title")}
							description={t("settings:process.error.description")}
						/>
					)}
					{conf.process === "SUBMITTED" && (
						<GroupPage
							color={conf.color}
							icon={FaCheck}
							title={t("settings:process.submitted.title")}
							description={t("settings:process.submitted.description")}
						/>
					)}
					{conf.process === "LOADED" && (
						<>
							<Group title={t("settings:group_title.room")}>
								{conf.origin.current?.settings.roomType === "server" && (
									<Item
										title={t("settings:items.room_open.title")}
										description={t("settings:items.room_open.description")}
										color={conf.color}
										icon={FaListCheck}
									>
										<ItemWrapper>
											<Toggle
												name="rp"
												color={conf.color}
												size={70}
												items={[
													{
														label: t(
															"settings:items.room_open.options.private"
														),
														id: "server",
														checked: conf.roomType === "server",
														onChange: () => conf.changeRoomType("server"),
													},
													{
														label: t("settings:items.room_open.options.public"),
														id: "global",
														checked: conf.roomType === "public",
														onChange: () => conf.changeRoomType("public"),
													},
												]}
											/>
										</ItemWrapper>
										{conf.roomTypeWarn && (
											<Warn content={t("settings:items.room_open.warn")} />
										)}
									</Item>
								)}
								<Item
									title={t("settings:items.room_name.title")}
									description={t("settings:items.room_name.description")}
									color={conf.color}
									icon={BiSolidRename}
								>
									<ItemWrapper>
										<TextBox
											attr="text"
											label={t("settings:items.room_name.label")}
											placeholder={t("settings:items.room_name.placeholder", {
												username: conf.origin.current?.username,
											})}
											color={conf.color}
											preFill={conf.roomName}
											maxLength={20}
											onChange={conf.changeRoomName}
										/>
									</ItemWrapper>
								</Item>
								<Item
									title={t("settings:items.capacity.title")}
									description={t("settings:items.capacity.description")}
									color={conf.color}
									icon={FaUsers}
								>
									<ItemWrapper>
										<TextBox
											attr="number"
											label={t("settings:items.capacity.label")}
											placeholder={t("settings:items.capacity.placeholder")}
											color={conf.color}
											preFill={conf.capacity}
											maxLength={2}
											onChange={conf.changeCapacity}
										/>
									</ItemWrapper>
									{conf.capacityWarn && (
										<Warn
											content={t("settings:items.capacity.warn")}
											important
										/>
									)}
								</Item>
								{conf.origin.current?.settings.roomType === "public" && (
									<Item
										title={t("settings:items.password.title")}
										description={t("settings:items.password.description")}
										color={conf.color}
										icon={PiPasswordFill}
									>
										<ItemWrapper>
											<TextBox
												attr="text"
												label={t("settings:items.password.label")}
												placeholder={t("settings:items.password.placeholder")}
												color={conf.color}
												preFill={conf.password}
												maxLength={20}
												onChange={conf.changePassword}
											/>
										</ItemWrapper>
									</Item>
								)}
							</Group>
							<Group title={t("settings:group_title.game")}>
								{(conf.origin.current?.modes.length ?? 1) > 1 && (
									<Item
										title={t("settings:items.mode.title")}
										description={t("settings:items.mode.description")}
										color={conf.color}
										icon={MdOutlineModeStandby}
									>
										<ItemWrapper wrap>
											{conf.origin.current?.modes.map((mode) => (
												<Radio
													label={mode.name}
													name="set-modes"
													id={mode.id}
													color={conf.color}
													description={mode.description}
													onChange={conf.changeMode}
													checked={conf.mode === mode.id}
												/>
											))}
										</ItemWrapper>
									</Item>
								)}
								<Item
									title={t("settings:items.start_in_morning.title")}
									description={t("settings:items.start_in_morning.description")}
									color={conf.color}
									icon={MdWbSunny}
								>
									<ItemWrapper>
										<Toggle
											name="start-in-morning"
											color={conf.color}
											size={125}
											items={[
												{
													label: t(
														"settings:items.start_in_morning.options.night"
													),
													id: "night",
													checked: !conf.startInMorning,
													onChange: () => conf.setStartInMorning(false),
												},
												{
													label: t(
														"settings:items.start_in_morning.options.daytime"
													),
													id: "daytime",
													checked: conf.startInMorning,
													onChange: () => conf.setStartInMorning(true),
												},
											]}
										/>
									</ItemWrapper>
								</Item>
								<Item
									title={t("settings:items.skip_arguments.title")}
									description={t("settings:items.skip_arguments.description")}
									color={conf.color}
									icon={IoMdMicrophone}
								>
									<ItemWrapper>
										<Toggle
											name="arguments"
											color={conf.color}
											size={125}
											items={[
												{
													label: t(
														"settings:items.skip_arguments.options.no_skip"
													),
													id: "no-skip-arguments",
													checked: !conf.skipArguments,
													onChange: () =>
														conf.changeSkipArgumentsAndVote(false),
												},
												{
													label: t(
														"settings:items.skip_arguments.options.skip"
													),
													id: "skip-arguments",
													checked: conf.skipArguments,
													onChange: () => conf.setSkipArguments(true),
												},
											]}
										/>
									</ItemWrapper>
								</Item>
								<Item
									title={t("settings:items.skip_final_vote.title")}
									description={t("settings:items.skip_final_vote.description")}
									color={conf.color}
									icon={FaPoll}
								>
									<ItemWrapper>
										<Toggle
											name="vote"
											color={conf.color}
											size={125}
											items={[
												{
													label: t(
														"settings:items.skip_final_vote.options.no_skip"
													),
													id: "no-skip-vote",
													checked: !conf.skipFinalVote,
													onChange: () => conf.setSkipFinalVote(false),
												},
												{
													label: t(
														"settings:items.skip_final_vote.options.skip"
													),
													id: "skip-vote",
													checked: conf.skipFinalVote,
													onChange: () => conf.changeSkipArgumentsAndVote(true),
												},
											]}
										/>
									</ItemWrapper>
								</Item>
								<Item
									title={t("settings:items.display_vote_sender.title")}
									description={t(
										"settings:items.display_vote_sender.description"
									)}
									color={conf.color}
									icon={MdPreview}
								>
									<ItemWrapper>
										<Toggle
											name="display-vote-sender"
											color={conf.color}
											size={125}
											items={[
												{
													label: t(
														"settings:items.display_vote_sender.options.display"
													),
													id: "display",
													checked: conf.displayVoteSender,
													onChange: () => conf.setDisplayVoteSender(true),
												},
												{
													label: t(
														"settings:items.display_vote_sender.options.no_display"
													),
													id: "no-display",
													checked: !conf.displayVoteSender,
													onChange: () => conf.setDisplayVoteSender(false),
												},
											]}
										/>
									</ItemWrapper>
								</Item>
							</Group>
							<Group title={t("settings:group_title.role")}>
								<Item
									title={t("settings:items.citizen_team.title")}
									description={t("settings:items.citizen_team.description")}
									color={CitizenBlue}
									icon={TeamIcon}
								>
									<ItemWrapper wrap>
										{conf.origin.current?.roles.map(
											(role) =>
												role.team === "citizen" && (
													<CheckBox
														label={role.name}
														id={role.id}
														name="citizen"
														color={CitizenBlue}
														checked={!conf.excludeRoles.has(role.id)}
														onChange={conf.changeExcludeRoles}
													/>
												)
										)}
									</ItemWrapper>
									{conf.citizenRoleWarn && (
										<Warn content={t("settings:items.citizen_team.warn")} />
									)}
								</Item>
								<Item
									title={t("settings:items.mafia_team.title")}
									description={t("settings:items.mafia_team.description")}
									color={MafiaRed}
									icon={TeamIcon}
								>
									<ItemWrapper wrap>
										{conf.origin.current?.roles.map(
											(role) =>
												role.team === "mafia" && (
													<CheckBox
														label={role.name}
														id={role.id}
														name="mafia"
														color={MafiaRed}
														checked={!conf.excludeRoles.has(role.id)}
														onChange={conf.changeExcludeRoles}
													/>
												)
										)}
									</ItemWrapper>
									{conf.mafiaRoleWarn && (
										<Warn
											content={t("settings:items.mafia_team.warn")}
											important
										/>
									)}
								</Item>
							</Group>
						</>
					)}
					{conf.process === "LOADED" && (
						<SaveBar
							tr={t}
							color={conf.color}
							hoverWhiteColor={conf.hoverWhiteColor}
							isEdited={conf.isEdited}
							importantWarns={conf.importantWarnCount}
							onReset={conf.reset}
							onSubmit={conf.onSubmit}
						/>
					)}
				</Container>
			</Wrapper>
		</>
	);
};

export default SettingsPage;
