import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-family: "LINESeedKR", "LINESeedJP", -apple-system, BlinkMacSystemFont,
		system-ui, "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
		"Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		sans-serif;
	  font-size: 16px;
	  @media (max-width: 700px) {
		  font-size: 14px;
	  }
  }

  body {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-color: ${({ theme }) => theme.backgroundColor}
  }

  h1 {
    font-weight: 800;
  }

  h2 {
    font-weight: 600;
  }

  .font-xs {
    font-size: 0.75rem
  }

  .font-sm {
    font-size: 0.85rem
  }

  .font-base {
    font-size: 1rem
  }

  .font-lg {
    font-size: 1.25rem
  }

  .font-xl {
    font-size: 1.5rem
  }
`;
