import styled from "styled-components";
import { Skeleton } from "../../../components/skeleton/skeleton.style";

const RoomType = styled.p`
	display: inline-block;
	color: #fff;
	background-color: #ffffff34;
	padding: 5px 10px;
	border-radius: 5px;
	margin-bottom: 10px;
`;

const RoomCode = styled.p`
	color: #fff;
	font-weight: 600;
	margin-bottom: 5px;
`;

const RoomName = styled.p`
	color: #fff;
	font-weight: 800;
	margin-bottom: 30px;
`;

const HeaderWrapper = styled.div`
	position: fixed;
	top: 50px;
	z-index: 2;
`;

interface HeaderProps {
	roomType: string;
	code: string;
	name: string;
}

export const Header = (props: HeaderProps) => {
	return (
		<HeaderWrapper>
			<RoomType className="font-sm">{props.roomType}</RoomType>
			<RoomCode className="font-base">{props.code}</RoomCode>
			<RoomName className="font-xl">{props.name}</RoomName>
		</HeaderWrapper>
	);
};

export const SkeletonHeader = () => {
	return (
		<HeaderWrapper>
			<RoomType>
				<Skeleton width={60} height={10} color="#fff" />
			</RoomType>
			<RoomCode>
				<Skeleton width={120} height={16} color="#fff" />
			</RoomCode>
			<RoomName>
				<Skeleton width={150} height={26} color="#fff" />
			</RoomName>
		</HeaderWrapper>
	);
};
