import { HTMLInputTypeAttribute } from "react";
import { Input, Title, Wrapper } from "./textbox.style";

interface TextBoxProps {
	attr: HTMLInputTypeAttribute;
	label: string;
	placeholder: string;
	color: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	preFill?: string;
	maxLength?: number;
}

const TextBox = (props: TextBoxProps) => {
	return (
		<Wrapper>
			<Title className="font-sm">{props.label}</Title>
			<Input
				className="font-base"
				type={props.attr}
				pattern={props.attr === "number" ? "[0-9]*" : undefined}
				placeholder={props.placeholder}
				color={props.color}
				onChange={props.onChange}
				value={props.preFill}
				maxLength={props.maxLength}
			/>
		</Wrapper>
	);
};

export default TextBox;
