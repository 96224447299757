import { ThemeProvider } from "styled-components";
import { useThemeDetector } from "./style/use_theme_detector";
import { darkTheme, lightTheme } from "./style/pallete";
import { GlobalStyle } from "./style/global";
import SettingsPage from "./pages/settings";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import MainPage from "./pages/main";

function App() {
	const isDark = useThemeDetector();
	return (
		<>
			<HelmetProvider>
				<BrowserRouter>
					<ThemeProvider theme={isDark ? darkTheme : lightTheme}>
						<GlobalStyle />
						<Routes>
							<Route path="/" element={<MainPage />} />
							<Route path="/s" element={<SettingsPage />} />
							<Route path="/s/:code" element={<SettingsPage />} />
						</Routes>
					</ThemeProvider>
				</BrowserRouter>
			</HelmetProvider>
		</>
	);
}

export default App;
