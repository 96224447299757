import styled from "styled-components";

export const Skeleton = styled.div<{
	height: number;
	width: number;
	color?: string;
}>`
	content: "";
	height: ${({ height }) => `${height}px`};
	width: ${({ width }) => `${width}px`};
	border-radius: ${({ height }) => `${height / 3}px`};
	opacity: 25%;
	background-color: ${(props) => props.color ?? props.theme.textColor};

	@keyframes sparkle {
		0%,
		100% {
			opacity: 25%;
		}
		50% {
			opacity: 40%;
		}
	}

	animation: sparkle 2.5s linear infinite;
`;
