import styled from "styled-components";

export const Label = styled.label`
	display: inline-flex;
	align-items: center;
	padding: 10px;
	border-radius: 10px;
	transition: all 0.25s;
	cursor: pointer;
`;

export const Input = styled.input`
	display: none;

	&:not(:checked) + ${Label} {
		color: ${({ theme }) => theme.unselectedTextColor};
		background-color: ${({ theme }) => theme.unselectedBoxColor};
	}

	&:checked + ${Label} {
		font-weight: 600;
		color: #fff;
		background-color: ${(props) => props.color};
	}
`;
