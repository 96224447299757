import { useState, useEffect } from "react";

export const useThemeDetector = () => {
	const [isDark, setIsDark] = useState(
		window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)").matches
	);

	useEffect(() => {
		const detectMode = () => {
			if (
				window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches
			) {
				setIsDark(true);
			} else {
				setIsDark(false);
			}
		};
		detectMode();

		const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

		try {
			darkMediaQuery.addEventListener("change", (e) => {
				detectMode();
			});
		} catch (e1) {
			try {
				darkMediaQuery.addListener((e) => {
					detectMode();
				});
			} catch (e2) {
				console.error(e2);
			}
		}

		return () => {
			try {
				darkMediaQuery.removeEventListener("change", (e) => {
					detectMode();
				});
			} catch (e1) {
				try {
					darkMediaQuery.removeListener((e) => {
						detectMode();
					});
				} catch (e2) {
					console.error(e2);
				}
			}
		};
	}, []);

	return isDark;
};
