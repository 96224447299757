import { Helmet } from "react-helmet-async";
import { useAPI } from "./use_api";
import { Main, Navigation } from "./main.style";
import { BaseData, MafiaCharacterImageURL } from "./api_data";
import { Navigate } from "react-router-dom";

const MainPage = () => {
	return <Navigate to="/s" />;
	/*
	const api = useAPI();
	const { t } = api;

	return (
		<>
			<Helmet>
				<title>{t("main:meta_data.title")}</title>
				<meta name="theme-color" content={api.data?.color ?? BaseData.color} />
			</Helmet>
			<Navigation />
			<Main bgImageUrl={api.data?.bannerImageUrl ?? BaseData.bannerImageUrl}>
				<div className="banner">
					<img
						src={api.data?.characterImageUrl ?? BaseData.characterImageUrl}
					/>
					<div>
						<h1 className="font-xl">{t("main:title")}</h1>
					</div>
				</div>
			</Main>
		</>
	);*/
};

export default MainPage;
