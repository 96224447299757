import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { APIData, RoomType } from "./api_data";
import { BaseGray, ErrorRed, SubmittedGreen } from "../../style/base_colors";

type Process = "LOADING" | "LOADED" | "ERROR" | "SUBMITTED";

const APIServerURI = "https://api-mafia.craftie.io";

export const useSettings = (code: string) => {
	const origin = useRef<APIData | null>(null);
	const { t, i18n } = useTranslation();

	const [process, setProcess] = useState<Process>("LOADING");
	const changeProcess = (p: Process) => {
		setProcess(p);
		if (p === "ERROR") {
			setColor(ErrorRed);
		} else if (p === "SUBMITTED") {
			setColor(SubmittedGreen);
		}
	};

	const [roomType, setRoomType] = useState<RoomType>("server");
	const [roomTypeWarn, setRoomTypeWarn] = useState<boolean>(false);
	const changeRoomType = (r: RoomType) => {
		setRoomType(r);
		setRoomTypeWarn(r === "public");
	};

	const [roomName, setRoomName] = useState<string>("");
	const changeRoomName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRoomName(e.target.value);
	};

	const [color, setColor] = useState<string>(BaseGray);
	const [hoverWhiteColor, setHoverWhiteColor] = useState<string>(BaseGray);
	const [hoverBlackColor, setHoverBlackColor] = useState<string>(BaseGray);
	const [mode, setMode] = useState<string>("original");
	const changeMode = (e: React.ChangeEvent<HTMLInputElement>) => {
		const mode = e.target.id;
		const colorBase = origin.current?.modes.find((m) => m.id === mode);
		setMode(mode);
		setColor(colorBase?.mainColor ?? BaseGray);
		setHoverWhiteColor(colorBase?.hoverWhiteColor ?? BaseGray);
		setHoverBlackColor(colorBase?.hoverBlackColor ?? BaseGray);
	};

	const [capacity, setCapacity] = useState<string>("8");
	const [capacityWarn, setCapacityWarn] = useState<boolean>(false);
	const changeCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCapacity(e.target.value);
		setCapacityWarn(
			e.target.value === "" ||
				Number.isNaN(+e.target.value) ||
				!Number.isInteger(+e.target.value) ||
				!(+e.target.value <= 20 && +e.target.value >= 8)
		);
	};

	const [password, setPassword] = useState<string>("");
	const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const [excludeRoles, setExcludeRoles] = useState<Set<string>>(
		new Set<string>()
	);
	const [changeDetector, setChangeDetector] = useState<boolean>(false);
	const [citizenRoleWarn, setCitizenRoleWarn] = useState<boolean>(false);
	const [mafiaRoleWarn, setMafiaRoleWarn] = useState<boolean>(false);
	const changeExcludeRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setExcludeRoles((set) => {
				set.delete(e.target.id);
				return set;
			});
		} else {
			setExcludeRoles((set) => {
				set.add(e.target.id);
				return set;
			});
		}

		setChangeDetector(!changeDetector);
		setCitizenRoleWarn(
			(origin.current?.roles.filter(
				(r) => r.team === "citizen" && !excludeRoles.has(r.id)
			).length ?? 0) < 1
		);

		setMafiaRoleWarn(
			(origin.current?.roles.filter(
				(r) => r.team === "mafia" && !excludeRoles.has(r.id)
			).length ?? 0) < 1
		);
	};

	const [startInMorning, setStartInMorning] = useState<boolean>(false);
	const [skipArguments, setSkipArguments] = useState<boolean>(false);
	const [skipFinalVote, setSkipFinalVote] = useState<boolean>(false);
	const [displayVoteSender, setDisplayVoteSender] = useState<boolean>(false);
	const changeSkipArgumentsAndVote = (skip: boolean) => {
		setSkipArguments(skip);
		setSkipFinalVote(skip);
	};

	const [isEdited, setEdited] = useState<boolean>(false);
	const isEqual = <T,>(a: T[], b: T[]) => {
		a.sort();
		b.sort();
		return a.length === b.length && a.every((v, i) => v === b[i]);
	};

	const [importantWarnCount, setImportantWarnCount] = useState<number>(0);
	const countImportantWarns = () => {
		let count = 0;
		if (capacityWarn) count++;
		if (mafiaRoleWarn) count++;
		setImportantWarnCount(count);
	};

	const initialize = (data: APIData) => {
		const s = data.settings;
		const colorBase = data.modes.find((m) => m.id === s.mode);

		setRoomType(s.roomType);
		setMode(s.mode);
		setColor(colorBase?.mainColor ?? BaseGray);
		setHoverWhiteColor(colorBase?.hoverWhiteColor ?? BaseGray);
		setHoverBlackColor(colorBase?.hoverBlackColor ?? BaseGray);
		setRoomName(s.name);
		setCapacity(`${s.capacity}`);
		setPassword(s.password);
		setExcludeRoles((set) => {
			set.clear();
			set = new Set<string>(s.excludeRoles);
			return set;
		});
		setStartInMorning(s.startInMorning);
		setSkipArguments(s.skipArguments);
		setSkipFinalVote(s.skipFinalVote);
		setDisplayVoteSender(s.displayVoteSender);
	};

	const removeWarns = () => {
		setRoomTypeWarn(false);
		setCapacityWarn(false);
		setCitizenRoleWarn(false);
		setMafiaRoleWarn(false);
	};

	const reset = () => {
		origin.current && initialize(origin.current);
		setEdited(false);
		removeWarns();
	};

	useEffect(() => {
		const s = origin.current?.settings;
		if (!!!s) {
			return;
		}

		setEdited(
			roomType !== s.roomType ||
				mode !== s.mode ||
				roomName !== s.name ||
				+capacity !== s.capacity ||
				password !== s.password ||
				startInMorning !== s.startInMorning ||
				skipArguments !== s.skipArguments ||
				skipFinalVote !== s.skipFinalVote ||
				displayVoteSender !== s.displayVoteSender ||
				!isEqual(s.excludeRoles, Array.from(excludeRoles))
		);
		countImportantWarns();
	}, [
		roomType,
		mode,
		roomName,
		capacity,
		password,
		startInMorning,
		skipArguments,
		skipFinalVote,
		displayVoteSender,
		Array.from(excludeRoles),
	]);

	useEffect(() => {
		(async () => {
			try {
				i18n.changeLanguage(navigator.language);
				origin.current = await fetch(APIServerURI + "/s/" + code).then((res) =>
					res.json()
				);

				if (!!!origin.current) {
					changeProcess("ERROR");
					return;
				}

				initialize(origin.current);
				changeProcess("LOADED");
				i18n.changeLanguage(origin.current.language);
			} catch {
				changeProcess("ERROR");
			}
		})();
	}, []);

	const onSubmit = async () => {
		window.scrollTo(0, 0);
		changeProcess("SUBMITTED");
		setEdited(false);
		fetch(APIServerURI + "/s/" + code, {
			method: "POST",
			body: JSON.stringify({
				roomType: roomType,
				mode: mode,
				name: roomName,
				capacity: +capacity,
				password: password,
				excludeRoles: Array.from(excludeRoles),
				startInMorning: startInMorning,
				skipFinalVote: skipFinalVote,
				skipArguments: skipArguments,
				displayVoteSender: displayVoteSender,
			}),
		}).catch(() => {});
	};

	return {
		t,
		origin,
		process,

		color,
		hoverWhiteColor,
		hoverBlackColor,

		roomType,
		changeRoomType,
		roomTypeWarn,

		roomName,
		changeRoomName,

		mode,
		changeMode,

		capacity,
		changeCapacity,
		capacityWarn,

		password,
		changePassword,

		excludeRoles,
		changeExcludeRoles,
		citizenRoleWarn,
		mafiaRoleWarn,

		startInMorning,
		setStartInMorning,

		skipArguments,
		setSkipArguments,

		skipFinalVote,
		setSkipFinalVote,

		changeSkipArgumentsAndVote,

		displayVoteSender,
		setDisplayVoteSender,

		isEdited,
		importantWarnCount,
		reset,
		onSubmit,
	};
};
