import styled from "styled-components";

export const Wrapper = styled.div<{ size?: number; index: number }>`
	display: inline-flex;
	align-items: center;
	padding: 5px;
	border-radius: 15px;
	background-color: ${({ theme }) => theme.unselectedBoxColor};
	z-index: 20;

	&::after {
		position: absolute;
		content: "dd";
		color: #ffffff00;
		background-color: ${(props) => props.color};
		width: ${({ size }) => (!!size ? `${size}px` : "150px")};
		border-radius: 10px;
		padding: 10px;
		z-index: 10;
		transition: all 0.25s;
		margin-left: ${(props) => {
			if (props.index === 0) return 0;
			const size = !!props.size ? props.size : 150;
			return size * props.index + 20;
		}}px;
	}
`;

export const Label = styled.label<{ size?: number }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => (!!size ? `${size}px` : "150px")};
	padding: 10px;
	transition: all 0.25s;
	cursor: pointer;
	border-radius: 10px;
	z-index: 30;
`;

export const Input = styled.input<{ index: number }>`
	display: none;

	&:not(:checked) + ${Label} {
		color: ${({ theme }) => theme.unselectedTextColor};
	}

	&:checked + ${Label} {
		color: #fff;
		font-weight: 600;
	}
`;
