import styled from "styled-components";

export const Label = styled.label`
	display: block;
	height: 70px;
	width: 120px;
	padding: 15px;
	border-radius: 10px;
	overflow: scroll;
	transition: all 0.25s;
	cursor: pointer;

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	&::before {
		content: "";
		position: absolute;
		margin-left: 110px;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background-color: ${(props) => props.color};
		transition: all 0.25s;
	}
`;

export const Input = styled.input`
	display: none;

	&:not(:checked) + ${Label} {
		color: ${({ theme }) => theme.unselectedTextColor};
		background-color: ${({ theme }) => theme.unselectedBoxColor};
	}

	&:checked + ${Label} {
		color: #fff;
		background-color: ${(props) => props.color};
	}

	&:not(:checked) + ${Label}::before {
		box-shadow: 0 0 0 10px #fff inset;
	}

	&:checked + ${Label}::before {
		box-shadow: 0 0 0 3px #fff inset;
	}
`;
