import styled from "styled-components";
import { BaseGray } from "../../../style/base_colors";

export const BackgroundColor = styled.div`
	position: fixed;
	top: 0px;
	width: 100vw;
	height: 200px;
	background-color: ${({ color }) => (!!color ? color : BaseGray)};
	z-index: 1;
	transition: all 0.25s;
`;

export const Wrapper = styled.div`
	margin: 0 auto;
	height: auto;
	width: 620px;

	@media (max-width: 700px) {
		width: auto;
		padding: 0 10px;
	}
`;

export const Container = styled.div<{ isEdited?: boolean }>`
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 20px;
	margin-top: 150px;
	margin-bottom: ${({ isEdited }) => (isEdited ? "120px" : "50px")};
	background-color: ${({ theme }) => theme.wrapperBoxColor};
	z-index: 3;
	transition: all 0.25s;
`;
